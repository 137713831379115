import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject as GatsbyImageFluid } from 'gatsby-image';

/**
 * @TODO Choose between placeholder background for hero and for blog posts.
 */
export const getHeroPlaceholderBackground = (): GatsbyImageFluid => {
  return useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg/vozzi-background-placeholder-img.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2480, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `).backgroundImage.childImageSharp.fluid;
};
