import { FluidObject as GatsbyImageFluid } from 'gatsby-image';
import { WpPostI } from '../types/WpFeedData';
import { getHeroPlaceholderBackground } from './getHeroBackgroundPlaceholder';

export const getFeaturedImgForBlogLayout = (post: WpPostI): GatsbyImageFluid => {
  let featuredImg = post.featured_media && post.featured_media.localFile ? post.featured_media.localFile.childImageSharp?.fluid : null;

  if (!featuredImg) {
    featuredImg = getHeroPlaceholderBackground();
  }

  return featuredImg;
};
